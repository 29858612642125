import { Layout } from "antd";
import { useState } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import "./App.css";
import Login from "./components/Auth/Login";
import Clients from "./components/Clients/routes/index";
import Header from "./components/Header/Header";
import Dashboard from "./components/Home/Home";
import Agents from "./components/Leads/Agents";
import Applications from "./components/Leads/Applications";
import LeftNavigation from "./components/LeftNavigation/LeftNavigation";
import Staff from "./components/Settings/UsersControls/StaffUser/Staff";
import Users from "./components/Users/routes/index";
import history from "./history";

export const HeaderFooterWrap = (props) => {
  return (
    <Layout
      className="flex-row"
      style={{
        minHeight: "100vh",
      }}
    >
      <LeftNavigation
        state={props.state}
        setState={props.setState}
        activeMenu={props?.state.activeMenu}
        setActiveMenu={(val) => {
          props.setState({
            ...props?.state,
            activeMenu: val,
          });
        }}
      />
      <Layout className="site-layout main-content">
        <Layout.Content>
          <Header />
          {props.children}
        </Layout.Content>
      </Layout>
      {/* <Footer /> */}
      {/* <div className="grid main-area">
        <div className="g-header pt1">
          <Header />
        </div>
        <div className="g-nav">
          <LeftNavigation />
        </div>
        <div className="g-content">
          {props.children}
        </div>
        <div className="g-footer">
          <Footer />
        </div>
      </div> */}
    </Layout>
  );
};
const RedirectLogin = () => {
  return <Redirect to={"/admin/login"} />;
};

function App() {
  const [state, setState] = useState({
    collapsed: false,
    selectedKey: "8",
    activeMenu: "dashboard",
  });
  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <div>
            <Route exact path={"/"} component={RedirectLogin} />
            <Route path="/admin/login" component={Login} exact />
            <Route path="/admin/resetpassword/" component={Login} />
            <Route
              path="/admin/dashboard"
              render={({ match }) => {
                return (
                  <HeaderFooterWrap state={state} setState={setState}>
                    <Dashboard path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
            <Route
              path="/admin/clients"
              render={({ match }) => {
                return (
                  <HeaderFooterWrap state={state} setState={setState}>
                    <Clients path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
            <Route
              path="/admin/users"
              render={({ match }) => {
                return (
                  <HeaderFooterWrap state={state} setState={setState}>
                    <Users path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
            <Route
              path="/admin/staff-users"
              render={({ match }) => {
                return (
                  <HeaderFooterWrap state={state} setState={setState}>
                    <Staff path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
            <Route
              path="/admin/leads"
              render={({ match }) => {
                return (
                  <HeaderFooterWrap state={state} setState={setState}>
                    <Agents path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
            <Route
              path="/admin/applications"
              render={({ match }) => {
                return (
                  <HeaderFooterWrap state={state} setState={setState}>
                    <Applications path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
          </div>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
