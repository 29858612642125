import { ActionTypesFactory } from "../../utils";

export const Employees = ActionTypesFactory("Employees", "Employees");
export const UsersList = ActionTypesFactory("Users", "UsersList");
export const StaffUsersList = ActionTypesFactory("Staff", "UsersList");
export const StaffUser = ActionTypesFactory("Staff", "User");
export const StaffUserDelete = ActionTypesFactory("Staff", "UserDelete");
export const AddStaffUser = ActionTypesFactory("Staff", "AddUser");
export const AddAgent = ActionTypesFactory("Agent", "AddUser");
export const Agent = ActionTypesFactory("Agent", "User");
export const AgentList = ActionTypesFactory("Agent", "UserList");
export const ApplicationList = ActionTypesFactory("Agent", "AppList");
export const UsersListExport = ActionTypesFactory("Users", "UsersListExport");
export const ClientListExport = ActionTypesFactory(
  "Client",
  "ClientListExport"
);
export const EmployeesStatus = ActionTypesFactory(
  "EmployeesStatus",
  "Employees"
);
export const AddEmployees = ActionTypesFactory("AddEmployees", "Employees");
export const DeleteEmployees = ActionTypesFactory(
  "DeleteEmployees",
  "Employees"
);

export const usersList = (payload) => {
  return {
    type: UsersList.REQUEST,
    payload,
  };
};

export const staffUserList = (payload) => {
  return {
    type: StaffUsersList.REQUEST,
    payload,
  };
};

export const staffUserShow = (payload) => {
  return {
    type: StaffUser.REQUEST,
    payload,
  };
};

export const staffUserDelete = (payload) => {
  return {
    type: StaffUserDelete.REQUEST,
    payload,
  };
};

export const addStaffUser = (payload) => {
  return {
    type: AddStaffUser.REQUEST,
    payload,
  };
};

export const addAgent = (payload) => {
  return {
    type: AddAgent.REQUEST,
    payload,
  };
};

export const showAgent = (payload) => {
  return {
    type: Agent.REQUEST,
    payload,
  };
};

export const agentsList = (payload) => {
  return {
    type: AgentList.REQUEST,
    payload,
  };
};

export const applicationsList = (payload) => {
  return {
    type: ApplicationList.REQUEST,
    payload,
  };
};

export const clientListExport = (payload) => {
  return {
    type: ClientListExport.REQUEST,
    payload,
  };
};

export const usersListExport = (payload) => {
  return {
    type: UsersListExport.REQUEST,
    payload,
  };
};

export const employees = (payload) => {
  return {
    type: Employees.REQUEST,
    payload,
  };
};

export const employeesStatus = (payload) => {
  return {
    type: EmployeesStatus.REQUEST,
    payload,
  };
};

export const addEmployees = (payload) => {
  return {
    type: AddEmployees.REQUEST,
    payload,
  };
};

export const deleteEmployees = (payload) => {
  return {
    type: DeleteEmployees.REQUEST,
    payload,
  };
};

// export const commits = (payload) => {
//   let str = "";
//   Object.keys(payload).forEach((item) => {
//     str = str + `${item}:${payload[item]} `;
//   });
//   payload = encodeURIComponent(str);
//   return {
//     type: Commits.REQUEST,
//     payload,
//   };
// };
