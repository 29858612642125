import { ReducerFunctions } from "../../utils";
import {
  AddCompany,
  AddStaffCompany,
  BaseCurrencies,
  CompanyEnable,
  CompanyList,
  CompanyDetails,
  UploadDocument,
  UpdateDocument,
  DeleteDocument,
  GetDocument,
  Leads,
  StaffCompanyDetails,
  StaffCompanyList,
  OnboardPreview,
  GetAllCountries,
} from "./clients";

export const add_company_reducer = ReducerFunctions(AddCompany, {});
export const base_currencies_reducer = ReducerFunctions(BaseCurrencies, {});
export const company_list_reducer = ReducerFunctions(CompanyList, {});
export const company_detail_reducer = ReducerFunctions(CompanyDetails, {});
export const upload_document_details = ReducerFunctions(UploadDocument, {});
export const update_document_details = ReducerFunctions(UpdateDocument, {});
export const delete_document_details = ReducerFunctions(DeleteDocument, {})
export const get_document_details = ReducerFunctions(GetDocument, {});
export const leads_reducer = ReducerFunctions(Leads, {});

export const add_staff_company_reducer = ReducerFunctions(AddStaffCompany, {});
export const get_staff_company_reducer = ReducerFunctions(
  StaffCompanyDetails,
  {}
);
export const enable_staff_company_reducer = ReducerFunctions(CompanyEnable, {});
export const company_staff_list_reducer = ReducerFunctions(
  StaffCompanyList,
  {}
);
export const onboard_preview_reducer = ReducerFunctions(OnboardPreview, {})
export const get_all_countries_reducer = ReducerFunctions(GetAllCountries, {})
