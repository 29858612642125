import { Menu } from "antd";
import React, { useEffect } from "react";
import DashboardIcon from "../../assets/icons/dashboard_icon.svg";
import ClientIcon from "../../assets/icons/Client.svg";
import UserIcon from "../../assets/icons/User_icon.svg";
import CardsIcon from "../../assets/icons/Cards_icon.svg";
import CardsImportIcon from "../../assets/icons/Crads_imports_icon.svg";
import StaffIcon from "../../assets/icons/Staff_icon.svg";
import LeadIcon from "../../assets/icons/Leads_icon.svg";
import LOGO from "../../assets/images/forgo.png";
import ReportIcon from "../../assets/icons/report_icon.svg";
import { hasAccess, RouteConstants } from "../../utils";
import "./leftNavigation.css";
import Sider from "antd/lib/layout/Sider";
import { useHistory } from "react-router-dom";

const LeftNavigation = (props) => {
  const history = useHistory();
  const { activeMenu, setActiveMenu } = props;
  const active = localStorage.getItem("activeDetails");

  useEffect(() => {
    if (active) {
      setActiveMenu(active);
    } else {
      setActiveMenu("dashboard");
    }
    // setActiveMenu(active)
  }, [active, activeMenu]);

  const menuItems = [
    {
      key: "dashboard",
      icon: <img src={DashboardIcon} alt={"logo"} className="menu-logo" />,
      text: "Dashboard",
      link: RouteConstants.DASHBOARD,
      access: true,
    },
    {
      key: "clients",
      icon: <img src={ClientIcon} alt={"logo"} className="menu-logo" />,
      text: "Clients",
      link: RouteConstants.CLIENTS,
      access: hasAccess("clients", "write") || hasAccess("clients", "read"),
    },
    {
      key: "users",
      icon: <img src={UserIcon} alt={"logo"} className="menu-logo" />,
      text: "Users",
      link: RouteConstants.USERS,
      access:
        hasAccess("permission_users", "write") ||
        hasAccess("permission_users", "read"),
    },
    // {
    //   key: 'cards',
    //   icon: <img src={CardsIcon} alt={"logo"} className="menu-logo" />,
    //   text: 'Cards',
    //   link: RouteConstants.CARDS,
    //   access: hasAccess('cards', 'write') || hasAccess('cards', 'read'),
    // },
    // {
    //   key: 'cardImports',
    //   icon: <img src={CardsImportIcon} alt={"logo"} className="menu-logo" />,
    //   text: 'Card Imports',
    //   link: RouteConstants.CARD_IMPORTS,
    //   access: hasAccess('card_imports', 'write') || hasAccess('card_imports', 'read'),
    // },
    // {
    //   key: 'cardVendors',
    //   icon: <img src={ReportIcon} alt={"logo"} className="menu-logo" />,
    //   text: 'Card Vendors',
    //   link: RouteConstants.CARD_VENDORS,
    //   access: hasAccess('card_vendors', 'write') || hasAccess('card_vendors', 'read'),
    // },
    {
      key: "staffUsers",
      icon: <img src={StaffIcon} alt={"logo"} className="menu-logo" />,
      text: "Staff Users",
      link: RouteConstants.STAFF_USERS,
      access:
        hasAccess("staff_users", "write") || hasAccess("staff_users", "read"),
    },
    {
      key: "leads",
      icon: <img src={LeadIcon} alt={"logo"} className="menu-logo" />,
      text: "Agents",
      link: RouteConstants.LEADS,
      access: true,
    },
    {
      key: "applications",
      icon: <img src={LeadIcon} alt={"logo"} className="menu-logo" />,
      text: "Applications",
      link: "/admin/applications",
      access: true,
    },
  ];
  const menuItemsToRender = menuItems
    .filter((item) => item.access)
    .map((item) => (
      <div
        key={item.key}
        className={
          activeMenu === item.key ? `menu-item menu-selected ` : `menu-item`
        }
        onClick={() => {
          localStorage.setItem("activeDetails", item.key);
          history.push(item.link);
          setActiveMenu(item.key);
        }}
      >
        <span>
          {item.icon}
          <span className="nav-label">{item.text}</span>
        </span>
      </div>
    ));

  return (
    <div className="d-flex">
      <Sider trigger={null} className="left-menu">
        <div className="logo mt2">
          {/* <img src={LOGO} alt="" /> */}
          <h2 className="text">BANK</h2>
        </div>
        <Menu
          mode="inline"
          inlineCollapsed={props.state.collapsed}
          className="navigation-div"
          style={{ paddingTop: "0.8rem" }}
          selectedKeys={[props.state.selectedKey]}
          onClick={(info) => {
            localStorage.setItem("activeDetails", info.key);
            props.setState((s) => ({ ...s, selectedKey: info.key }));
          }}
        >
          {menuItemsToRender}
        </Menu>
      </Sider>
    </div>
  );
};

export default LeftNavigation;
