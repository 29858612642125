import React from "react";
import {
  Input,
  Form,
  Button,
  Typography,
  Divider,
  Row,
  Col,
} from "antd";
import { withRouter } from "react-router";
import "../clients.css";
const { Title } = Typography;

const ContactDetails = (props) => {
  const handleCancel = () => {
    props.history.push("/admin/clients/");
  };

  return (
    <>
      <div className="contentWrapper" style={{ height: "100%", flex: 1 }}>
        <Title level={5} className="cardTitle">
          Primary Contact
        </Title>
        <Divider />
        <Form
          layout="vertical"
          // onFinish={handleSubmit}
          className="settings_form"
        >
          <Row>
            <Col span={6}>
              <Form.Item label="Primary Contact Name" name="contact_name">
                <Input
                  placeholder="Primary Contact Name"
                  className="inputTag"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Primary Contact Number" name="contact_number">
                <Input
                  placeholder="Primary Contact Number"
                  className="inputTag"
                />
              </Form.Item>
            </Col>
          </Row>
          <Col span={12}>
            <Form.Item label="Primary Contact Email" name="contact_email">
              <Input placeholder="Primary Contact Email" className="inputTag" />
            </Form.Item>
          </Col>
          <Title level={5} className="cardTitle">
            BANK SPOC
          </Title>
          <Divider />
          <Row>
            <Col span={6}>
              <Form.Item label="BANK Contact Name" name="paycraft_contact_name">
                <Input
                  placeholder="BANK Contact Name"
                  className="inputTag"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="BANK Contact Number" name="paycraft_contact_number">
                <Input
                  placeholder="BANK Contact Number"
                  className="inputTag"
                />
              </Form.Item>
            </Col>
          </Row>
          <Col span={12}>
            <Form.Item label="BANK Contact Email" name="paycraft_contact_email">
              <Input placeholder="BANK Contact Email" className="inputTag" />
            </Form.Item>
          </Col>
          <div className="d-flex mt3">
            <Button key="1" htmlType="submit" className="formButton mt1">
              Save
            </Button>
            <Button
              key="2"
              className="cancelButton ml1 mt1"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default withRouter(ContactDetails);
