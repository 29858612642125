import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Typography,
  Upload,
  Modal,
} from "antd";
import Text from "antd/lib/typography/Text";
import { EyeFilled } from "@ant-design/icons";
import axios from "axios";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { get_card_vendors } from "../../../actions/cards";
import {
  addStaffCompany,
  AddStaffCompany,
  base_currencies,
  getAllCountries,
  getStaffCompanyDetails,
  OnboardPreview,
  onboardPreview,
  StaffCompanyDetails,
  updateDocument,
  uploadDocument,
} from "../../../actions/clients/clients";
import { apis } from "../../../config/APIs";
import usePayAxios from "../../../config/usePayApis";
import PasswordCheck from "../../Common/PasswordCheck";
import "../clients.css";
import moment from "moment";
import { getFileExtension } from "../../../utils";
import png_icon from "../../../assets/icons/pdf_file_icon.png";
const { TextArea } = Input;
const { Title } = Typography;
const { Dragger } = Upload;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const OrganisationProfile = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const addStaffCompRes = useSelector(
    (state) => state?.client?.addStaffCompany
  );

  const uploadDocumentDetails = useSelector(
    (state) => state?.client?.uploadDocument?.data?.data
  );

  const currencies = useSelector(
    (state) => state?.client?.currencies?.data?.data
  );

  const compRes = useSelector(
    (state) => state?.client?.getStaffCmp?.data?.data
  );
  const vendors = useSelector(
    (state) => state?.cards?.get_card_vendors?.data?.data
  );
  const onboardPreviewResponse = useSelector(
    (state) => state?.client?.onboardPreview?.data
  );
  const allCountries = useSelector((state) =>
    state?.client?.getAllCountries?.data?.data?.map((item, index) => ({
      ...item,
      value: item?.country_iso_code,
      label: (
        <span
          style={{
            color: "#212121",
            fontSize: 15,
          }}
        >
          <img
            src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${item?.country_iso_code?.toLowerCase()}.svg`}
            className="mr1"
            width={25}
            style={{
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
            }}
          />
          {`${item?.country_name} (${item?.country_iso_code})`}
        </span>
      ),
    }))
  );
  const location = useLocation();

  const [isAuto, setIsAuto] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState(false);
  const [uploadData, setUploadData] = useState([]);
  // const [uploadDetails, setUploadDetails] = useState({});
  // const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewImage, setPreviewImage] = useState('');
  // const [previewTitle, setPreviewTitle] = useState('');
  const [imageUrl, setImageUrl] = useState();

  const [rejectModal, setRejectModal] = useState(false);
  const [rejectText, setRejectText] = useState("");
  const [isPreviewOpen, setIsPreviewOpen] = useState({
    open: false,
    url: "",
  });

  const OrgProfileSchema = Yup.lazy((vals) => {
    let obj = {
      name: Yup.string().required("Name is required."),
      virtual_id: Yup.string()
        .nullable()
        .trim()
        .test(
          "is-virtual-id",
          `vertual ID is required and must be 16 characters`,
          function (value) {
            const { path, createError, originalValue } = this;
            if (
              originalValue &&
              (originalValue.length < 16 || originalValue.length > 16)
            ) {
              return createError({
                path,
                message: `vertual ID is required and must be 16 characters`,
              });
            } else {
              return true;
            }
          }
        ),
      // outlet_id: Yup.string().required("Outlet ID is required."),
      registration_no: Yup.string()
        .nullable()
        .required("Registration no is required."),
      logo: Yup.mixed(),
      address: Yup.string()
        .nullable()
        .matches("^[A-Za-z0-9$&,:;@#.-/ ]*$", "Enter valid address")
        .required("Address is required."),
      city: Yup.string().nullable().matches("^[A-Za-z ]*$", "Enter valid city"),
      state: Yup.string()
        .nullable()
        .matches("^[A-Za-z ]*$", "Enter valid state"),
      // country: Yup.string().nullable().matches("^[A-Za-z]*$", "Enter valid country"),
      pincode: Yup.string()
        .nullable()
        .matches("^[0-9]{5,8}$", "Enter valid pincode")
        .required("pincode is required")
        .min(6, "enter Valid pincode"),
      account_ifsc: Yup.string()
        .nullable()
        .matches("^[A-Za-z]{4}0[A-Za-z0-9]{6}$", "Enter valid ifsc code")
        .required("account ifsc is required")
        .min(11, "minimum 11 digits required")
        .max(16, "maximum 16 digits"),
      office_no: Yup.string().nullable().required("Office no is required."),
      gst_no: Yup.string()
        .nullable()
        .matches("^[A-Z0-9]{9,20}$", "Enter valid GST No"),
        // .required("Gst no is required.")
        // .min(15, "minimum 15 digits required")
        // .max(15, "maximum 15 digits required"),
      tan_no: Yup.string()
        .nullable()
        .matches("^[A-Z0-9]{9,20}$", "Enter valid TAN No"),
        // .required("Tan no is required.")
        // .min(10, "minimum 10 digits required")
        // .max(10, "maximum 10 digits required"),
      account_number: Yup.string()
        .nullable()
        .required("enter valid accountNumber")
        .min(9, "minimum 9 digits required")
        .max(18, "maximum 18 digits are required"),
      primary_contact_name: Yup.string()
        .nullable()
        .required("Primary contact name is required"),
      primary_email: Yup.string()
        .nullable()
        .email("Enter valid primary email.")
        .required("Primary email is required."),
      email: Yup.string()
        .nullable()
        .email("Enter valid email.")
        .required("email is required."),
      primary_phone: Yup.string()
        .nullable()
        .required("Primary phone is required.")
        .min(10, "minimum 10 digits required")
        .max(12, "max 12 digits"),
      password: Yup.string().nullable().required("password is required"),
      password_confirmation: Yup.string()
        .nullable()
        .required("confirmation password is required"),
    };
    if (!isAuto && !vals.id) {
      obj = {
        ...obj,
        password_confirmation: Yup.string()
          .nullable()
          .required("Password confirmation is required.")
          .oneOf([Yup.ref("password"), null], "Passwords must match"),
        password: Yup.string().nullable().required("Password is required."),
      };
    } else {
      if (obj.password && obj.password_confirmation) {
        delete obj.password;
        delete obj.password_confirmation;
      }
    }
    return Yup.object().shape(obj);
  });

  const ifscRef = useRef();

  const initialObj = {
    name: "",
    // title: "Mr.",
    primary_email: "",
    primary_contact_name: "",
    registration_no: "",
    logo: "",
    address: "",
    city: "",
    state: "",
    country: null,
    pincode: "",
    office_no: "",
    gst_no: "",
    tan_no: "",
    email: "",
    primary_phone: "",
    password_confirmation: "",
    password: "",
    mo_id: "",
    virtual_id: "",
    outlet_id: "",
    account_name: "",
    account_number: "",
    account_ifsc: "",
    bank_name: "",
    bank_address: "",
    bank_branch: "",
    vendor_id: null,
    account_ifsc: "",
    fiscal_year: "FINANCIALYEAR",
  };

  const formik = useFormik({
    initialValues: initialObj,
    validationSchema: OrgProfileSchema,
    onSubmit: (vals) => {
      let obj = { ...vals };
      console.log("organisation profile obj", { obj: obj });
      obj.logo = uploadData;
      if (isAuto) {
        delete obj.password;
        delete obj.password_confirmation;
      } else {
        delete obj.password_confirmation;
      }
      if (typeof obj.logo === "string" || !obj.logo) {
        delete obj.logo;
      }
      if (typeof obj.gst_certificate === "string" || !obj.gst_certificate) {
        delete obj.gst_certificate;
      }
      if (typeof obj.incorporation === "string" || !obj.incorporation) {
        delete obj.incorporation;
      }
      if (typeof obj.other === "string" || !obj.other) {
        delete obj.other;
      }
      if (typeof obj.cheque === "string" || !obj.cheque) {
        delete obj.cheque;
      }
      if (typeof obj.address_proof === "string" || !obj.address_proof) {
        delete obj.address_proof;
      }
      if (typeof obj.incorporation === "object") {
        if (!obj?.incorporation?.id) {
          delete obj.incorporation;
        } else {
          obj.incorporation = [obj?.incorporation?.id];
        }
      }
      if (typeof obj.other === "object") {
        if (!obj?.other?.id) {
          delete obj.other;
        } else {
          obj.other = [obj?.other?.id];
        }
      }
      dispatch(addStaffCompany(obj));
    },
    enableReinitialize: true,
  });
  useEffect(() => {
    dispatch(getAllCountries());
    return () => {
      dispatch({ type: OnboardPreview.RESET });
    };
  }, []);

  useEffect(() => {
    if (addStaffCompRes?.success) {
      message.success(addStaffCompRes?.data?.message);
    }
    if (addStaffCompRes?.success === true) {
      dispatch({
        type: AddStaffCompany.RESET,
      });
      props.history.push("/admin/clients");
    }

    if (addStaffCompRes?.success === false) {
      message.error(
        addStaffCompRes?.data?.errors ?? addStaffCompRes?.data?.message
      );
      dispatch({
        type: AddStaffCompany.RESET,
      });
    }
    // eslint-disable-next-line
  }, [addStaffCompRes]);

  useEffect(() => {
    if (location?.state && location?.state?.id) {
      dispatch(getStaffCompanyDetails(location?.state?.id));
      dispatch(
        onboardPreview({
          company_id: location?.state?.id,
          params: { preview: true, country: location?.state?.country },
        })
      );
      // dispatch(uploadDocument({params:{id:location?.state?.id}}))
    }
    // eslint-disable-next-line
  }, [location?.state]);

  useEffect(() => {
    if (compRes && compRes?.id) {
      formik.setValues({
        ...initialObj,
        ...compRes,
        vendor_id: compRes?.card_vendors?.card_vendor_id,
        logo: [compRes?.logo?.id],
      });
      formik.setFieldValue("email", compRes?.primary_contact_email);

      if (compRes?.logo) {
        setImageUrl(compRes?.logo?.url);
        setUploadData([compRes?.logo.id]);
      }
    }
    console.log("organisation profile obj compRes", { compRes: compRes });
    // eslint-disable-next-line
  }, [compRes]);

  useEffect(() => {
    setUploadData(uploadDocumentDetails);
  }, [uploadDocumentDetails]);

  const onFileChange = async (info) => {
    getBase64(info.file, (url) => {
      setImageUrl(url);
    });

    let uniqueNo = "";
    {
      props.location.state?.id
        ? (uniqueNo = location?.state?.id)
        : (uniqueNo = uuidv4());
    }
    const formData = new FormData();
    if (compRes?.logo?.id) {
      formData.append(
        "attachmentData",
        JSON.stringify([{ filename: info.file.name, id: compRes.logo.id }])
      );
      formData.append("files", info.file);
    } else {
      formData.append("files", info.file);
      formData.append("name", "logo");
      formData.append("record_type", "Company");
      formData.append("record_id", uniqueNo);
    }

    // formik.setFieldValue("logo",info.file)

    {
      compRes?.logo
        ? dispatch(updateDocument(formData, props.location.state?.id))
        : dispatch(uploadDocument(formData));
    }
  };

  // const checkFile = (file) => {
  //   const files =
  //     file.type === "image/jpeg" ||
  //     file.type === "image/png" ||
  //     file.type === "image/png";

  //   if (!files) {
  //     message.error("You can only upload images!");
  //     return false;
  //   }

  //   return true;
  // };
  const checkFile = (file, fileList) => {
    const fileName = getFileExtension(file?.name)?.toLowerCase();
    if (["jpg", "jpeg", "png"]?.includes(fileName)) {
      return false;
    } else {
      message.error(
        <span className="messageText">{"Please Upload jpg or png files"}</span>
      );
      return Upload.LIST_IGNORE;
    }
  };
  useEffect(() => {
    dispatch(get_card_vendors({ params: { isCsv: true } }));
    dispatch(base_currencies());
    return () => {
      formik.setValues(initialObj);
      setImageUrl("");
      dispatch({
        type: StaffCompanyDetails.RESET,
      });
    };
    // eslint-disable-next-line
  }, []);

  // const storefileLog = (log) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     logo: log.name,
  //   }));
  // };

  const {
    onCall: clientApproval,
    data: clientApprovalData,
    loading: approvalLoading,
    reset: approvalReset,
  } = usePayAxios({ api: apis?.onboardClientApproval, method: "post" });
  const {
    onCall: clientReject,
    data: clientRejectData,
    loading: rejectLoading,
    reset: rejectReset,
  } = usePayAxios({ api: apis?.onboardClientReject, method: "post" });

  useEffect(() => {
    if (
      clientApprovalData?.error == false ||
      clientRejectData?.error == false
    ) {
      message.success(
        <span className="messageText">
          {clientApprovalData?.data?.message ?? clientRejectData?.data?.message}
        </span>
      );
      rejectReset();
      approvalReset();
      history.push("/admin/clients/");
    } else if (
      clientApprovalData?.error == true ||
      clientRejectData?.error == true
    ) {
      message.error(
        <span className="messageText">
          {clientApprovalData?.data?.message ?? clientRejectData?.data?.message}
        </span>
      );
      rejectReset();
      approvalReset();
    }
  }, [clientRejectData, clientApprovalData]);
  const hiddenKeys = [
    "pan_number",
    "cin_number",
    "year_of_incorporation",
    "business_entity_type",
    "industry_type",
    "no_of_employees",
    "business_website_link",
    "address_line_two",
    "url",
    "card_number",
    "name_on_card",
    "dob",
    "incorporation",
    "gst_certificate",
    "upload_aadhaar_front",
    "upload_aadhaar_back",
    "upload_pan",
  ];
  console.log("organisation profile values", { errors: formik.errors });
  return (
    <>
      <Modal
        visible={isPreviewOpen?.open}
        onCancel={() => {
          setIsPreviewOpen({
            open: false,
            url: "",
          });
        }}
        footer={null}
        width="80%"
        centered
        className="preview_section_modal"
      >
        {isPreviewOpen?.fileType == "pdf" ? (
          <div style={{ width: "100%", height: "100vh" }}>
            <iframe
              src={isPreviewOpen?.url}
              allow="fullscreen"
              style={{ width: "100%", height: "100%", margin: "auto" }}
              title="description"
            ></iframe>
          </div>
        ) : (
          <img
            src={isPreviewOpen?.url}
            alt="preview image"
            style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
          />
        )}
      </Modal>
      <Modal
        visible={rejectModal}
        title="Rejecting OnBoard Client"
        footer={[]}
        closable={false}
      >
        <TextArea
          name="address"
          value={rejectText}
          placeholder="Enter your reason"
          autoSize={{ minRows: 3, maxRows: 5 }}
          className="inputTag"
          onChange={(e) => {
            setRejectText(e?.target?.value);
          }}
        />
        <div className="d-flex">
          <button
            disabled={!rejectText}
            className="reject mt1"
            onClick={() => {
              clientReject({
                data: { company_id: compRes?.id, reject_reason: rejectText },
              });
            }}
          >
            Reject
          </button>
          <button
            className="cancel ml1 mt1"
            onClick={() => {
              setRejectModal(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <div className="contentWrapper" style={{ height: "100%", flex: 1 }}>
        <div className="flex-between">
          <Title level={5} className="cardTitle" style={{ marginBottom: 0 }}>
            Organisation Profile
          </Title>
          {!compRes?.is_onboard_client &&
            !compRes?.kyb_completed &&
            compRes?.kyc_status === "kyc_submitted" ? (
            <div style={{ position: "relative", right: 25 }}>
              <button
                key="1"
                htmlType="button"
                className="approve"
                style={{ width: "100px" }}
                onClick={() => {
                  clientApproval({ data: { company_id: compRes?.id } });
                }}
              >
                Approve
              </button>
              <button
                className="reject ml1"
                onClick={() => {
                  setRejectModal(true);
                }}
                style={{ width: "100px" }}
              >
                Reject
              </button>
            </div>
          ) : null}
        </div>
        <Divider />
        <Form
          layout="vertical"
          onFinish={formik.handleSubmit}
          className="settings_form"
        >
          <Row>
            <Col span={12}>
              <Form.Item label="Company Name" required>
                <Input
                  placeholder="Company Name"
                  className="inputTag"
                  name="name"
                  value={formik.values["name"]}
                  onChange={formik.handleChange}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="err-msg">{formik.errors.name}</div>
                )}
              </Form.Item>
              <Form.Item label="Primary Company Email" required>
                <Input
                  placeholder="Primary Company Email"
                  disabled={location?.state?.id}
                  className="inputTag"
                  name="primary_email"
                  value={formik.values["primary_email"]}
                  onChange={formik.handleChange}
                />
                {formik.touched.primary_email &&
                  formik.errors.primary_email && (
                    <div className="err-msg">{formik.errors.primary_email}</div>
                  )}
              </Form.Item>
              <Form.Item label="Company Registration No" required>
                <Input
                  placeholder="Company Registration No"
                  className="inputTag"
                  name="registration_no"
                  value={formik.values["registration_no"]}
                  onChange={formik.handleChange}
                />
                {formik.touched.registration_no &&
                  formik.errors.registration_no && (
                    <div className="err-msg">
                      {formik.errors.registration_no}
                    </div>
                  )}
              </Form.Item>
              <Form.Item label="Address" required>
                <TextArea
                  name="address"
                  value={formik.values["address"]}
                  // onChange={this.onChange}
                  placeholder="Enter Address"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  className="inputTag"
                  onChange={(e) => {
                    if (!/^[A-Za-z0-9$&,:;@#.-/ ]*$/.test(e.target.value)) {
                      return;
                    }
                    formik.setFieldValue("address", e.target.value);
                  }}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className="err-msg">{formik.errors.address}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={12} className="uploadSection align-items-center">
              <Text>Upload your company logo</Text>
              {/* <Dropzone
                file={formik.values["logo"]}
                acceptedFileTypes={{
                  'image/png': ['.jpeg', '.jpg', '.png'],
                }}
                setFile={(file) => {
                  formik.setFieldValue("logo", file);
                }}
                logo={formik.values["logo"]}
              /> */}
              <Dragger
                // fileList={uploadData.selectedFileList}
                // beforeUpload={()=>false}
                // beforeUpload={(file) => {
                //   const allowedTypes = ['.png', '.jpg'];
                //   if (!allowedTypes.includes(file.type)) {
                //     message.error("it allow only images");
                //     return false;
                //   }
                //   return true;
                // }}
                beforeUpload={checkFile}
                name="logo"
                // accept=".png,.jpg"
                multiple={false}
                maxCount={1}
                onChange={onFileChange}
                showUploadList={{ showRemoveIcon: false }}
              // defaultFileList={uploadData}
              >
                {/* <img src={imageUrl}/> */}

                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="avatar"
                    style={{
                      width: "150px",
                      height: "120px",
                    }}
                  />
                ) : (
                  <>
                    <p
                      style={{ fontSize: "0.8rem !important" }}
                      className="ant-upload-text"
                    >
                      {"Drag Your image file here"}
                      <br />
                      {"or Click here to Upload"}
                    </p>

                    {/* <p
                      style={{ fontSize: "0.8rem !important" }}
                      className="ant-upload-text"
                    >
                      {("max_7")}
                    </p> */}
                  </>
                )}
              </Dragger>
              <p className="hint" style={{ paddingTop: "1rem" }}>
                Preferred Image Size: 240px x 240px @ 72 DPI Maximum size of
                1MB.
              </p>
              {formik.touched.logo && formik.errors.logo && (
                <div className="err-msg">{formik.errors.logo}</div>
              )}
            </Col>
          </Row>
          <Row className="gap1">
            <Col span={6}>
              <Form.Item label="City" name="city">
                <Input
                  placeholder="City"
                  className="inputTag"
                  name="city"
                  value={formik.values["city"]}
                  onChange={(e) => {
                    if (
                      !/^[A-Za-z ]*$/.test(e.target.value) &&
                      e.target.value
                    ) {
                      return;
                    }
                    formik.setFieldValue("city", e.target.value);
                  }}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className="err-msg">{formik.errors.city}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="State" name="state">
                <Input
                  placeholder="State"
                  className="inputTag"
                  name="state"
                  value={formik.values["state"]}
                  onChange={(e) => {
                    if (
                      !/^[A-Za-z ]*$/.test(e.target.value) &&
                      e.target.value
                    ) {
                      return;
                    }
                    formik.setFieldValue("state", e.target.value);
                  }}
                />
                {formik.touched.state && formik.errors.state && (
                  <div className="err-msg">{formik.errors.state}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              {/* <Form.Item label="Country" name="country">
                <Input
                  placeholder="Country"
                  className="inputTag"
                  name="country"
                  value={formik.values["country"]}
                  onChange={(e) => {
                    if (
                      !/^[A-Za-z ]*$/.test(e.target.value) &&
                      e.target.value
                    ) {
                      return;
                    }
                    formik.setFieldValue("country", e.target.value);
                  }}
                />
                {formik.touched.country && formik.errors.country && (
                  <div className="err-msg">{formik.errors.country}</div>
                )}
              </Form.Item> */}
              <Form.Item label="Country">
                <Select
                  name="country"
                  className="inputTag selectTag"
                  placeholder="Select Country"
                  value={formik.values["country"]}
                  onChange={(val) => {
                    formik.setFieldValue("country", val);
                  }}
                  options={allCountries}
                  allowClear
                />
                {/* </Select> */}
                {formik.touched.vendor_id && formik.errors.vendor_id && (
                  <div className="err-msg">{formik.errors.vendor_id}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Pincode" name="pincode" required>
                <Input
                  placeholder="Pincode"
                  className="inputTag"
                  name="pincode"
                  value={formik.values["pincode"]}
                  maxLength={8}
                  onChange={(e) => {
                    if (
                      !/^[0-9A-Za-z]*$/.test(e.target.value) &&
                      e.target.value
                    ) {
                      return;
                    }
                    formik.setFieldValue(
                      "pincode",
                      e.target.value?.toUpperCase()
                    );
                  }}
                />
                {formik.touched.pincode && formik.errors.pincode && (
                  <div className="err-msg">{formik.errors.pincode}</div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row className="gap1">
            <Col span={6}>
              <Form.Item label="Office Number" name="office_no" required>
                <Input
                  placeholder="Office Number"
                  className="inputTag"
                  name="office_no"
                  maxLength={12}
                  value={formik.values["office_no"]}
                  onChange={(e) => {
                    if (!/^[0-9]*$/.test(e.target.value) && e.target.value) {
                      return;
                    }
                    formik.setFieldValue("office_no", e.target.value);
                  }}
                />
                {formik.touched.office_no && formik.errors.office_no && (
                  <div className="err-msg">{formik.errors.office_no}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="GSTIN No">
                <Input
                  placeholder="GSTIN No"
                  className="inputTag"
                  name="gst_no"
                  maxLength={20}
                  value={formik.values["gst_no"]}
                  onChange={(e) => {
                    if (!/^[A-Za-z0-9]*$/.test(e.target.value)) {
                      return;
                    }
                    formik.setFieldValue(
                      "gst_no",
                      e.target.value?.toUpperCase()
                    );
                  }}
                />
                {formik.touched.gst_no && formik.errors.gst_no && (
                  <div className="err-msg">{formik.errors.gst_no}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="TAN No">
                <Input
                  placeholder="TAN No"
                  className="inputTag"
                  name="tan_no"
                  maxLength={20}
                  value={formik.values["tan_no"]}
                  onChange={(e) => {
                    if (!/^[A-Za-z0-9]*$/.test(e.target.value)) {
                      return;
                    }
                    formik.setFieldValue(
                      "tan_no",
                      e.target.value?.toUpperCase()
                    );
                  }}
                />
                {formik.touched.tan_no && formik.errors.tan_no && (
                  <div className="err-msg">{formik.errors.tan_no}</div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Title level={5} className="cardTitle">
            Primary Contact
          </Title>
          <Divider />
          <Row className="gap1">
            <Col span={6}>
              <Form.Item
                label="Primary Contact Name"
                // className="primary-contact"
                required
              >
                {/* <Select
                  name="title"
                  placeholder="Title"
                  className="inputTag selectTag"
                  value={formik.values["title"]}
                  disabled={location?.state?.id}
                  onChange={(val) => {
                    formik.setFieldValue("title", val);
                  }}
                >
                  <Select.Option value={"Ms."}>Ms.</Select.Option>
                  <Select.Option value={"Mr."}>Mr.</Select.Option>
                </Select> */}
                <Input
                  placeholder="Primary Contact Name"
                  className="inputTag"
                  name="primary_contact_name"
                  value={formik.values["primary_contact_name"]}
                  onChange={formik.handleChange}
                  disabled={location?.state?.id}
                />
                {formik.touched.primary_contact_name &&
                  formik.errors.primary_contact_name && (
                    <div className="err-msg">
                      {formik.errors.primary_contact_name}
                    </div>
                  )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Primary Contact Number"
                name="contact_number"
                required
              >
                <Input
                  placeholder="Primary Contact Number"
                  className="inputTag"
                  name="primary_phone"
                  value={formik.values["primary_phone"]}
                  disabled={location?.state?.id}
                  maxLength={15}
                  onChange={(e) => {
                    if (!/^[0-9]*$/.test(e.target.value) && e.target.value) {
                      return;
                    }
                    formik.setFieldValue("primary_phone", e.target.value);
                  }}
                />
                {formik.touched.primary_phone &&
                  formik.errors.primary_phone && (
                    <div className="err-msg">{formik.errors.primary_phone}</div>
                  )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Contact Email" required>
                <Input
                  placeholder="Contact Email"
                  className="inputTag"
                  disabled={location?.state?.id}
                  name="email"
                  value={formik.values["email"]}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="err-msg">{formik.errors.email}</div>
                )}
              </Form.Item>
            </Col>
          </Row>
          {!formik.values?.id && (
            <>
              <Title level={5} className="cardTitle">
                Password
              </Title>
              <Divider />
              <Row>
                <Col>
                  <div className="radio-grp mb1">
                    <Input
                      type={"radio"}
                      name="isAuto"
                      autoComplete="new-password"
                      onChange={() => {
                        setIsAuto(true);
                        formik.setFieldValue("password", "");
                        formik.setFieldValue("password_confirmation", "");
                      }}
                      checked={isAuto}
                    />
                    <div>
                      <label>Automatically Generate Password</label>
                      <span>
                        Email will be sent to the client to set the password.
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="radio-grp">
                    <Input
                      type={"radio"}
                      checked={!isAuto}
                      name="isAuto"
                      onChange={() => {
                        setIsAuto(false);
                      }}
                    />
                    <label>Create Password</label>
                  </div>
                  {!isAuto && (
                    <Row className="gap1 mt1">
                      <Col span={6}>
                        <Form.Item
                          label="Password"
                          name="contact_number"
                          required
                        >
                          <Input.Password
                            placeholder="Password"
                            className="inputTag"
                            name="password"
                            autoComplete="new-password"
                            value={formik.values["password"]}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.password &&
                            formik.errors.password && (
                              <div className="err-msg">
                                {formik.errors.password}
                              </div>
                            )}
                          <PasswordCheck
                            password={formik.values.password}
                            dark
                            setPasswordCheck={setPasswordCheck}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Confirm Password"
                          name="password_confirmation"
                          required
                        >
                          <Input.Password
                            placeholder="Confirm Password"
                            className="inputTag"
                            allowClear={false}
                            name="password_confirmation"
                            value={formik.values["password_confirmation"]}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.password_confirmation &&
                            formik.errors.password_confirmation && (
                              <div className="err-msg">
                                {formik.errors.password_confirmation}
                              </div>
                            )}
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </>
          )}
          <Title level={5} className="mt2">
            Bank Details
          </Title>
          <Divider />
          <Row className="gap1">
            <Col span={7}>
              <Form.Item label="Account IFSC" required>
                <Input
                  placeholder="Account IFSC"
                  name="account_ifsc"
                  className="inputTag"
                  value={formik.values["account_ifsc"]}
                  onChange={(e) => {
                    if (ifscRef.current) {
                      clearTimeout(ifscRef.current);
                    }
                    ifscRef.current = setTimeout(() => {
                      axios
                        .get(apis.getIfscDetails, {
                          headers: {
                            Authorization:
                              "Bearer " + localStorage.getItem("token"),
                          },
                          params: {
                            ifsc_code: e.target.value?.toUpperCase(),
                          },
                        })
                        .then((res) => {
                          if (!res?.data?.error) {
                            formik.setFieldValue(
                              `bank_name`,
                              res?.data?.data?.bank_name
                            );
                            formik.setFieldValue(
                              `bank_branch`,
                              res?.data?.data?.branch
                            );
                            formik.setFieldValue(
                              `bank_address`,
                              res?.data?.data?.address
                            );
                          } else {
                            formik.setFieldValue(`bank_name`, "");
                            formik.setFieldValue(`bank_branch`, "");
                            formik.setFieldValue(`bank_address`, "");
                            if (e.target.value)
                              message.error(
                                <span className="messageText">
                                  Bank Details not found.
                                </span>
                              );
                          }
                        })
                        .catch(() => {
                          if (e.target.value)
                            message.error(
                              <span className="messageText">
                                Bank Details not found.
                              </span>
                            );
                          formik.setFieldValue(`bank_name`, "");
                          formik.setFieldValue(`bank_branch`, "");
                          formik.setFieldValue(`bank_address`, "");
                        });
                    }, 700);
                    formik.setFieldValue(
                      `account_ifsc`,
                      e.target.value?.toUpperCase()
                    );
                  }}
                />
                {formik.touched.account_ifsc && formik.errors.account_ifsc && (
                  <div className="err-msg">{formik.errors.account_ifsc}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Account name">
                <Input
                  placeholder="Account name"
                  name="account_name"
                  className="inputTag"
                  value={formik.values["account_name"]}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Account Number" required>
                <Input
                  placeholder="Account Number"
                  name="account_number"
                  className="inputTag"
                  value={formik.values["account_number"]}
                  maxLength={18}
                  onChange={formik.handleChange}
                />
                {formik.touched.account_number &&
                  formik.errors.account_number && (
                    <div className="err-msg">
                      {formik.errors.account_number}
                    </div>
                  )}
              </Form.Item>
            </Col>
          </Row>
          <Row className="gap1">
            <Col span={7}>
              <Form.Item label="Bank name">
                <Input
                  placeholder="Bank name"
                  name="bank_name"
                  className="inputTag"
                  value={formik.values["bank_name"]}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Bank branch">
                <Input
                  placeholder="Bank branch"
                  name="bank_branch"
                  className="inputTag"
                  value={formik.values["bank_branch"]}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Bank address">
                <Input
                  placeholder="Bank address"
                  name="bank_address"
                  className="inputTag"
                  value={formik.values["bank_address"]}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
          </Row>
          {!["kyc_init"]?.includes(compRes?.kyc_status)
            ? Array.isArray(onboardPreviewResponse?.data) &&
            onboardPreviewResponse?.data?.map(
              (fieldsElement, fieldsIndex) =>
                !["Business Address", "Bank Account Details"]?.includes(
                  fieldsElement?.section_title
                ) && (
                  <Row className="gap1">
                    <Title level={5} className="mt2">
                      {fieldsElement?.section_title}
                    </Title>
                    <Divider className="mt0" />
                    {!["Business Address", "Bank Account Details"]?.includes(
                      fieldsElement?.section_title
                    ) &&
                      fieldsElement?.fields_list?.map(
                        (listElement, listIndex) => {
                          if (
                            hiddenKeys?.includes(listElement?.entity_column)
                          ) {
                            if (
                              listElement?.data_type == "string" ||
                              listElement?.data_type == "number"
                            ) {
                              return (
                                <Col span={7}>
                                  <Form.Item
                                    label={listElement?.entity_column_label}
                                  >
                                    <Input
                                      placeholder={
                                        listElement?.entity_column_label
                                      }
                                      name={listElement?.entity_column}
                                      className="inputTag"
                                      value={listElement?.value}
                                      // onChange={formik.handleChange}
                                      disabled={true}
                                    />
                                  </Form.Item>
                                </Col>
                              );
                            }
                            // else if (listElement?.data_type == "date") {
                            //   return <Col span={7}>
                            //     <Form.Item label={listElement?.entity_column_label}>
                            //       <DatePicker
                            //         name={listElement?.entity_column}
                            //         className="inputTag"
                            //         value={listElement?.value}
                            //         onChange={formik.handleChange}
                            //         disabled={true}
                            //       />
                            //     </Form.Item>
                            //   </Col>
                            // }
                            else if (listElement?.data_type == "dropdown") {
                              return (
                                <Col span={7}>
                                  <Form.Item
                                    label={listElement?.entity_column_label}
                                  >
                                    <Select
                                      name={listElement?.entity_column}
                                      className="inputTag selectTag"
                                      placeholder={
                                        listElement?.entity_column_label
                                      }
                                      options={listElement?.dropdown_fields}
                                      value={listElement?.value}
                                      disabled={true}
                                      allowClear
                                    />
                                  </Form.Item>
                                </Col>
                              );
                            } else if (listElement?.data_type == "document") {
                              return (
                                <Col
                                  span={`${listElement?.group ==
                                    "Upload Documents" ||
                                    [
                                      "upload_aadhaar_front",
                                      "upload_aadhaar_back",
                                    ]?.includes(listElement?.entity_column)
                                    ? 10
                                    : 17
                                    }`}
                                  className="uploadSection align-items-center mb1"
                                >
                                  <Text>
                                    {listElement?.entity_column_label}
                                  </Text>
                                  <Dragger
                                    name={listElement?.entity_column}
                                    disabled={true}
                                  >
                                    {listElement?.value ? (
                                      <img
                                        src={
                                          getFileExtension(
                                            listElement?.file_properties
                                              ?.filename
                                          ) == "pdf"
                                            ? png_icon
                                            : listElement?.value
                                        }
                                        alt="avatar"
                                        style={{
                                          width: "150px",
                                          height: "120px",
                                        }}
                                      />
                                    ) : (
                                      <>
                                        <p
                                          style={{
                                            fontSize: "0.8rem !important",
                                          }}
                                          className="ant-upload-text"
                                        >
                                          {"Drag Your image file here"}
                                          <br />
                                          {"or Click here to Upload"}
                                        </p>
                                      </>
                                    )}
                                  </Dragger>
                                  {getFileExtension(
                                    listElement?.file_properties?.filename
                                  )?.toLowerCase() === "pdf" ? (
                                    <>
                                      <div className="active_section d-flex mt1">
                                        <div
                                          className="preview_section"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <Button
                                            icon={<EyeFilled />}
                                            onClick={() => {
                                              setIsPreviewOpen(() => ({
                                                open: true,
                                                url: listElement?.value,
                                                fileType: getFileExtension(
                                                  listElement?.file_properties
                                                    ?.filename
                                                ),
                                              }));
                                            }}
                                            className="mr1"
                                            sty
                                          />
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                </Col>
                              );
                            } else if (listElement?.data_type == "date") {
                              return (
                                <Col span={7}>
                                  <Form.Item
                                    label={listElement?.entity_column_label}
                                  >
                                    <Input
                                      placeholder={
                                        listElement?.entity_column_label
                                      }
                                      name={listElement?.entity_column}
                                      className="inputTag"
                                      value={
                                        listElement?.entity_column ==
                                          "year_of_incorporation"
                                          ? moment(
                                            listElement?.value
                                          )?.format("YYYY")
                                          : listElement?.value
                                            ? moment(
                                              listElement?.value
                                            )?.format("DD-MM-YYYY")
                                            : listElement?.value
                                      }
                                      // onChange={formik.handleChange}
                                      disabled={true}
                                    />
                                  </Form.Item>
                                </Col>
                              );
                            }
                          }
                        }
                      )}
                  </Row>
                )
            )
            : null}

          {!["kyc_submitted", "kyc_pending"]?.includes(compRes?.kyc_status) ? (
            <div className="d-flex flex-end">
              <Button
                key="2"
                className="cancelButton ml1 mt1"
                onClick={() => history.push("/admin/clients/")}
                style={{ width: "100px" }}
                disabled={addStaffCompRes?.loading}
              >
                Cancel
              </Button>
              <Button
                key="1"
                htmlType="submit"
                className="ant-btn ant-btn-primary pp-main-button mt1 ml2"
                style={{ width: "100px" }}
                disabled={addStaffCompRes?.loading || passwordCheck}
              >
                {addStaffCompRes?.loading
                  ? location?.state?.id
                    ? "Updating..."
                    : "Creating..."
                  : location?.state?.id
                    ? "Update"
                    : "Create"}
              </Button>
            </div>
          ) : null}
        </Form>
      </div>
    </>
  );
};

export default withRouter(OrganisationProfile);
