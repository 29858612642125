import { Button, Input, Select, message } from "antd";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { AddAgent, Agent, addAgent, agentsList } from "../../actions/employees";
const AddStaff = ({ onCancel, selectedData, pageNumbers }) => {
  const dispatch = useDispatch();
  const add_staff = useSelector((state) => state?.agents?.add_agent?.data);
  const add_staff_loading = useSelector(
    (state) => state?.agents?.add_agent?.loading
  );
  const formik = useFormik({
    initialValues: {
      title: "Mr.",
      name: "",
      email: "",
      primary_phone: "",
      emp_id: "",
      location_tagging: [],
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email("Email must be valid.")
        .required("Email is required"),
      title: yup.string().required("Title is required"),
      primary_phone: yup
        .string()
        .required("Enter phone Number")
        .min(10, "Enter Valid Phone No")
        .max(10, "Enter Valid Phone No"),
      name: yup.string().required("Name is required"),
      location_tagging: yup
        .array()
        .of(yup.string().required("at least 1 Tag required"))
        .min(1, "at least 1 Tag required"),
    }),
    onSubmit: (vals) => {
      dispatch(addAgent(vals));
    },
  });

  useEffect(() => {
    formik.resetForm({});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (add_staff?.error === false && selectedData?.id) {
      message.success("User Updated Successfully");
      dispatch({
        type: AddAgent.RESET,
      });
      dispatch(
        agentsList({ params: { page_number: pageNumbers.page_number } })
      );
      onCancel();
      formik.resetForm();
      formik.setFieldValue("location_tagging", []);
    }
    if (add_staff?.error === false && selectedData?.id === undefined) {
      // {selectedData?.id ? message.success("Added User"):message.success("Added User")}
      message.success("User Created Successfully");
      // message.success("Added user");
      dispatch({
        type: AddAgent.RESET,
      });
      dispatch(agentsList());
      onCancel();
      formik.resetForm();
      formik.setFieldValue("location_tagging", []);
    } else if (add_staff?.error === true) {
      message.error(add_staff.message);
    }

    // eslint-disable-next-line
  }, [add_staff]);

  useEffect(() => {
    if (selectedData?.id === undefined) {
      dispatch({
        type: Agent.RESET,
      });
      formik.resetForm();
    }
  }, [selectedData?.id]);

  useEffect(() => {
    if (selectedData?.id) {
      formik.setValues({
        ...selectedData,
      });
    }
  }, [selectedData]);

  return (
    <>
      <form className="modal-form staff" onSubmit={formik.handleSubmit}>
        <div className="input-div">
          <label>
            Name<span className="error">*</span>
          </label>
          <div className="d-flex" style={{ gap: "1rem" }}>
            <Select
              style={{
                width: "5rem",
              }}
              options={[
                { label: "Mr.", value: "Mr." },
                { label: "Mrs.", value: "Mrs." },
                { label: "Miss.", value: "Miss." },
                { label: "Dr.", value: "Dr." },
              ]}
              value={formik.values.title}
              onChange={(value) => {
                formik.setFieldValue("title", value);
              }}
              placeholder={"Select"}
            />
            <Input
              autoComplete="off"
              placeholder="Enter Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              name="name"
            />
          </div>
          {formik.touched.name && formik.errors.name && (
            <div className="error">{formik.errors.name}</div>
          )}
        </div>
        <div className="input-div">
          <label className="required">
            Email<span className="error">*</span>
          </label>
          <Input
            disabled={selectedData?.id}
            autoComplete="new-password"
            placeholder="Enter Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            name="email"
          />
          {formik.touched.email && formik.errors.email && (
            <div className="error">{formik.errors.email}</div>
          )}
        </div>
        <div className="input-div">
          <label>
            Phone No<span className="error">*</span>
          </label>
          <Input
            maxLength={10}
            autoComplete="off"
            placeholder="Enter Phone No"
            value={formik.values.primary_phone}
            onChange={(e) => {
              if (Number.isNaN(Number(e.target.value)) && e.target.value) {
                return;
              }
              formik.setFieldValue("primary_phone", e.target.value);
            }}
            name="primary_phone"
          />
          {formik.touched.primary_phone && formik.errors.primary_phone && (
            <div className="error">{formik.errors.primary_phone}</div>
          )}
        </div>

        <div className="input-div">
          <label>Location Tagging</label>
          <Select
            className="w100"
            placeholder="Select Locations"
            mode="multiple"
            options={[
              "Hyderabad",
              "Delhi",
              "Mumbai",
              "Pune",
              "Chennai",
              "Visakhapatnam",
            ]?.map((ele) => ({ label: ele, value: ele }))}
            onChange={(vals) => {
              formik.setFieldValue("location_tagging", vals);
            }}
            value={formik.values.location_tagging}
          />

          {formik.touched.location_tagging &&
            formik.errors.location_tagging && (
              <div className="error">{formik.errors.location_tagging}</div>
            )}
        </div>
        <div className="d-flex">
          <Button
            key="1"
            htmlType="submit"
            className="formButton mt1"
            disabled={add_staff_loading}
            loading={add_staff_loading}
          >
            {selectedData?.id ? "Update" : "Save"}
          </Button>
          <Button
            key="2"
            className="cancelButton ml1 mt1"
            onClick={() => {
              onCancel();
              formik.resetForm();
              formik.setFieldValue("permission_ids", []);
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddStaff;
