import { Modal, Space, Select, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { headerTitles } from "../../actions/auth";
import {
  AddAgent,
  agentsList,
  applicationsList,
} from "../../actions/employees/employees";
import { hasAccess } from "../../utils";
import Table from "../Common/Table/Table";
import usePayAxios from "../../config/usePayApis";
import { apis } from "../../config/APIs";

const Applications = () => {
  const [currntPage, setCurrentPage] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [isModelVisible, setIsModelVisible] = useState(false);
  const data = useSelector(
    (state) => state?.agents?.get_applications?.data?.data
  );

  const dataLoading = useSelector(
    (state) => state?.agents?.get_applications?.loading
  );
  const totalRecords = useSelector(
    (state) => state?.agents?.get_applications?.data?.total_count
  );
  const employeesResponse = useSelector(
    (state) => state?.agents?.get_agents?.data?.data
  );

  const { onCall: assignTP, data: assignData } = usePayAxios({
    api: apis.assignThirdPartyAgent,
    method: "post",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      agentsList({
        params: {
          pagination: false,
        },
      })
    );

    dispatch(
      applicationsList({
        params: {
          page_number: 1,
        },
      })
    );

    dispatch(
      headerTitles({
        title: "Third Party Applications",
      })
    );
    dispatch({
      type: AddAgent.RESET,
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (assignData?.error === false) {
      message.success(assignData?.message);
      dispatch(
        applicationsList({
          params: {
            page_number: 1,
          },
        })
      );
      setIsModelVisible(false);
      setSelectedAssignee("");
    } else if (assignData?.error === true) {
      message.error(assignData?.message);
    }
  }, [assignData]);

  const columns = [
    {
      title: "Candidate Name",
      dataIndex: "candidate_details",
      key: "candidate_details",
      render: (text) => text.name,
    },
    {
      title: "Application Number",
      dataIndex: "candidate_applications",
      key: "candidate_applications",
      render: (text) => text.application_number,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <span className={`status status_${text}`}>{text}</span>,
    },
    hasAccess("staff_users", "write")
      ? {
          title: "Actions",
          dataIndex: "id",
          width: 150,
          key: "id",
          render: (text, record) =>
            record?.email !== localStorage.getItem("email") ? (
              <Space>
                <span
                  className="link"
                  onClick={() => {
                    setSelectedRecord(record);
                    setIsModelVisible(true);
                  }}
                >
                  Assign Agent
                </span>
              </Space>
            ) : null,
        }
      : {},
  ];
  const renderDeleteOption = () => {
    const roles = employeesResponse?.map((ele) => ({
      label: ele.name,
      value: ele.id,
    }));
    return (
      <>
        <Select
          onChange={(e) => setSelectedAssignee(e)}
          options={roles}
          style={{ width: 200 }}
          value={selectedAssignee ? selectedAssignee : null}
          className="mb1"
          placeholder="Select Third Party Agent"
        />
        <br />
        <div
          style={{
            display: "flex",
            marginTop: "17px",
          }}
        >
          <Button
            key="1"
            className="formButton"
            disabled={!selectedRecord?.id}
            onClick={() =>
              assignTP({
                data: {
                  id: selectedRecord?.id,
                  candidate_application_id:
                    selectedRecord?.candidate_applications?.id,
                  assigned_to: selectedAssignee,
                },
              })
            }
          >
            Assign
          </Button>
          <Button
            key="2"
            className="cancelButton ml1"
            onClick={() => setIsModelVisible(false)}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  };
  return (
    <>
      <Modal
        title={"Assign User"}
        visible={isModelVisible}
        onCancel={() => {
          setIsModelVisible(false);
        }}
        footer={[]}
        className="center-aligned-modal"
      >
        {renderDeleteOption()}
      </Modal>
      <Table
        columns={columns}
        placeholder={"Search by Email, Name, Mobile No"}
        loading={dataLoading}
        data={Array.isArray(data) ? data : []}
        pagination={{
          total: totalRecords || 0,
          // current: currntPage,
          onChange: (pagee) => {
            dispatch(applicationsList({ params: { page_number: pagee } }));
            setCurrentPage(pagee);
          },
        }}
        topFil={true}
        addBtn={true}
        handleSearch={(val) => {
          if (val) dispatch(applicationsList({ search_key: val }));
          else dispatch(applicationsList());
        }}
      />
    </>
  );
};

export default Applications;
